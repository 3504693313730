<template>
    <div>
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>打样</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="105px" class="login-form">
            
            <div class="block">
                <div class="header">打样图片</div>
                <div class="list" style="padding:10px 20px;">
                    <div class="flex flex-align-center">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.design_photos"></UploadImgs>
                        </div>
                        <div style="width:350px;margin-left:10px;">
                            <el-input type="textarea" :rows="3" v-model="ruleForm.desc" style="width:100%" placeholder="请输入细节说明"></el-input>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.design_photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.design_photos.length || 0}}/5</div>
                    </div>
                    <div>
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.design_photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.design_photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.design_photos.length || 0}}/5</div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="header">打样视频</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="打样视频"  label-width="105px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.design_photos"></UploadImgs>
                        </div>
                        <div class="font14-grey" v-if="ruleForm.design_photos" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.design_photos.length || 0}}/5</div>
                    </el-form-item>
                    <div style="padding:20px 0 20px 105px;">
                        <el-button size="small" type="primary" style="width:90px;" @click="submitForm()">确定</el-button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import localForage from 'localforage';
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { getRoles,delRol } from '@/service/purview';
    import UploadImgs from '@/components/UploadImgs.vue';
    export default {
        components: {
            UploadImgs
        },
        data() {
            return {
                rules: {
                    name: [{ required: true, message: '请输入单品名称', trigger: 'blur' }],
                    [`size.len`]: [{ required: true, message: '请填写单品长度', trigger: 'blur' }],
                    [`size.wlen`]: [{ required: true, message: '请填写单品宽度', trigger: 'blur' }],
                    [`size.height`]: [{ required: true, message: '请填写单品高度', trigger: 'blur' }],
                    desc: [{ required: true, message: '请输入设计理念', trigger: 'blur' }],
                },
                ruleForm:{
                    cat_id: null,
                    style_id: null,
                    series_id:null,
                    design_photos:[],
                    photos:[],
                    link:"",
                    ability:[],
                    radio:"",
                    size: {
                        len: null,
                        wlen: null,
                        height: null,
                    },
                },
                cats: [],
                styles: [],
                series:[],
                showImgs:false,
            }
        },
        created() {
            this.showImgs = true;
            var obj = {
                name:"喷漆",
                zhibiao:"执色、黏合度",
                bfb:"90%",
                yes:"是"
            }
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
            this.ruleForm.ability.push(obj);
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                // this.back();
                this.$router.push("/purview/submitFile")
                this.$message.success("保存成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 10px 20px;
    }
    .list>div {
        padding:10px 0;
        position:relative;
    }
</style>